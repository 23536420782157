import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import logo from "../../Assets/logo.png";
import styled from "styled-components";
import AppStepper from "./AppStepper";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

import { useAuth } from "../../hooks/useAuth";
import ContactInformation from "./ContactInformation";
import PaymentMethod from "./PaymentMethods";
import ShippingMethod from "./ShippingMethod";
import OrderConfirmation from "./OrderConfirmation";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartByUuidAsync,
  decrementFromCartAsync,
  getCartAsync,
  removeFromCartAsync,
} from "../../core/slices/cartSlice";
import { ChevronRight, Close } from "@mui/icons-material";
import { faClose, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { getShippingMethodsAsync } from "../../core/slices/checkoutSlice";

const HeaderContainer = styled(Box)`
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.03);
`;

const steps = ["Address", "Shipping", "Payment method", "Order Confirmation"];
const arabocSteps = ["العنوان", "الشحن", "طريقة الدفع", "تأكيد الطلب"];

const ProductImage = styled.img`
  width: 90px;
`;

const RegularText = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const CheckoutPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const { cart, status, addToCartStatus } = useSelector((state) => state.cart);
  const [openCart, setOpenCart] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [promoClicked, setPromoClicked] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mediumDevice = useMediaQuery(theme.breakpoints.up("md"));
  const { companyInformation } = useSelector((state) => state.company);

  // const [selectedShippingMethod, setSelectedShippingMethod] = useState(null);
  const { selectedShippingMethod } = useSelector((state) => state.checkout);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const handleIncrement = (item) => {
    dispatch(
      addToCartByUuidAsync(
        {
          product_id: item.product_id,
          quantity: 1,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {
          dispatch(getShippingMethodsAsync(personalInformation?.state?.id));
        }
      )
    );
  };

  useEffect(() => {
    if (companyInformation?.currency === "AED") {
      setPersonalInformation((prev) => ({
        ...prev,
        state: { id: 3798, name: "Dubai" },
        country: {
          id: 229,
          title: "United Arab Emirates",
          has_zipcode: true,
          states: [
            {
              id: 3796,
              name: "Abu Zabi",
            },
            {
              id: 3797,
              name: "Ajman",
            },
            {
              id: 3798,
              name: "Dubai",
            },
            {
              id: 3799,
              name: "Ras al-Khaymah",
            },
            {
              id: 3800,
              name: "Sharjah",
            },
            {
              id: 3801,
              name: "Sharjha",
            },
            {
              id: 3802,
              name: "Umm al Qaywayn",
            },
            {
              id: 3803,
              name: "al-Fujayrah",
            },
            {
              id: 3804,
              name: "ash-Shariqah",
            },
          ],
        },
      }));
    }
  }, [companyInformation?.currency]);

  const handleDecrement = (item) => {
    if (item.quantity <= 1) {
      handleRemove(item);
      dispatch(getShippingMethodsAsync(personalInformation?.state?.id));
      return;
    }
    dispatch(
      decrementFromCartAsync(
        {
          product_id: item.product_id,
          quantity: -1,
          phone: localStorage.getItem("phoneNumber"),
        },
        () => {
          dispatch(getShippingMethodsAsync(personalInformation?.state?.id));
        }
      )
    );
  };

  const handleRemove = (item) => {
    dispatch(removeFromCartAsync(item.id));
  };

  const [personalInformation, setPersonalInformation] = useState({
    fname: "",
    lname: "",
    email: "",
    district: null,
    country: {
      id: 121,
      title: "Lebanon",
      has_zipcode: false,
      states: [
        {
          id: 2150,
          name: "Beirut",
        },
        {
          id: 2151,
          name: "Jabal Lubnan",
        },
        {
          id: 2152,
          name: "Mohafazat Liban-Nord",
        },
        {
          id: 2153,
          name: "Mohafazat Mont-Liban",
        },
        {
          id: 2154,
          name: "Sidon",
        },
        {
          id: 2155,
          name: "al-Biqa",
        },
        {
          id: 2156,
          name: "al-Janub",
        },
        {
          id: 2157,
          name: "an-Nabatiyah",
        },
        {
          id: 2158,
          name: "ash-Shamal",
        },
      ],
    },
    state: { id: 2150, name: "Beirut" },
    street: "",
    phone: "",
    building: "",
    apartment: "",
    nearestLandmark: "",
    register: false,
  });
  const { user } = useAuth();

  useEffect(() => {
    dispatch(
      getCartAsync(
        promoClicked ? promoCode : "",
        selectedShippingMethod?.price,
        personalInformation?.state?.id
      )
    );
  }, [dispatch, selectedShippingMethod?.price, personalInformation?.state?.id]);

  const onPromoCodeButton = () => {
    setPromoClicked(true);
    dispatch(
      getCartAsync(
        promoCode,
        selectedShippingMethod?.price,
        personalInformation?.state?.id
      )
    );
  };

  const handleClosePromo = () => {
    setPromoCode("");
    setPromoClicked(false);
    dispatch(
      getCartAsync(
        null,
        selectedShippingMethod?.price,
        personalInformation?.state?.id
      )
    );
  };

  return (
    <>
      <GoogleReCaptcha onVerify={setCaptchaToken} />

      <Drawer open={openCart} onClose={() => setOpenCart(false)} anchor="right">
        <Box
          p={5}
          // width={mediumDevice ? 450 : 280}
          width={500}
          display={"flex"}
          flexDirection={"column"}
          flex={1}
          justifyContent={"space-between"}
        >
          <Box>
            <Box
              display="flex"
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <p className="p-text-bold">
                {t`Your Selection`} ({cart?.carts?.length || 0})
              </p>
              <Box display={"flex"} alignItems={"center"} gap={1}>
                {activeStep > 1 && (
                  <div
                    style={{
                      cursor: "pointer",
                      borderBottom: "1px solid #000",
                      fontWeight: 700,
                      padding: 0,
                      display: "inline-block",
                    }}
                    onClick={() => setActiveStep(1)}
                  >
                    {t`Edit`}
                  </div>
                )}

                <IconButton size="small" onClick={() => setOpenCart(false)}>
                  <FontAwesomeIcon icon={faClose} />
                </IconButton>
              </Box>
            </Box>
            <Box gap={3} display={"flex"} flexDirection={"column"}>
              {cart?.carts?.map((item) => (
                <Box
                  display={"flex"}
                  gap={2}
                  flexDirection={"row"}
                  //   alignItems={"flex-end"}
                >
                  <img
                    src={item?.image[0]}
                    className="products-image"
                    style={{ width: 90 }}
                    alt={item.product_name}
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Box mb={"auto"}>
                      <p className="p-text-bold">{item.product_name}</p>
                      <p className="p-text">
                        {item.color}, {item.size}
                      </p>
                    </Box>

                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"row"}
                      gap={1}
                    >
                      {activeStep < 2 && (
                        <IconButton
                          size="small"
                          onClick={() => handleDecrement(item)}
                        >
                          {addToCartStatus === "loading" ||
                          status === "loading" ? (
                            <CircularProgress
                              style={{ color: "#000" }}
                              size={18}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faMinus} />
                          )}
                        </IconButton>
                      )}
                      <RegularText>Qty: {item.quantity}</RegularText>
                      {activeStep < 2 && (
                        <IconButton
                          size="small"
                          onClick={() => handleIncrement(item)}
                        >
                          {addToCartStatus === "loading" ||
                          status === "loading" ? (
                            <CircularProgress
                              style={{ color: "#000" }}
                              size={18}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faPlus} />
                          )}
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                  <p
                    className="p-text"
                    style={{ marginLeft: "auto", marginTop: "auto" }}
                  >
                    {item.computed_price}{" "}
                    {localStorage?.getItem("currency") ??
                      companyInformation?.default_currency}
                  </p>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Grid container>
        <Grid item md={8} xs={12}>
          <HeaderContainer>
            <Grid container justifyContent={"center"}>
              <Grid item md={9} xs={12}>
                <Box>
                  <a href="/">
                    <img src={logo} alt="logo" className="logo" />
                  </a>
                  <Box mt={5}>
                    <AppStepper
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      steps={i18n.language === "ar" ? arabocSteps : steps}
                      completed={completed}
                      setCompleted={setCompleted}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </HeaderContainer>
          <Grid container justifyContent={"center"}>
            <Grid item md={9.5} xs={12}>
              {activeStep === 0 && (
                <ContactInformation
                  data={personalInformation}
                  onFinish={(data) => {
                    setActiveStep(1);
                    setPersonalInformation(data);
                  }}
                />
              )}
              {activeStep === 1 && (
                <ShippingMethod
                  stateId={personalInformation?.state?.id}
                  // setSelectedShippingMethod={setSelectedShippingMethod}
                  selectedShippingMethod={selectedShippingMethod}
                  onConfirm={() => setActiveStep(2)}
                />
              )}
              {activeStep === 2 && (
                <PaymentMethod
                  stateId={personalInformation?.state?.id}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  selectedPaymentMethod={selectedPaymentMethod}
                  country={personalInformation?.country}
                  onConfirm={() => setActiveStep(3)}
                />
              )}
              {activeStep === 3 && (
                <OrderConfirmation
                  promoClicked={promoClicked}
                  promoCode={promoCode}
                  setActiveStep={setActiveStep}
                  handleClosePromo={handleClosePromo}
                  onPromoCodeButton={onPromoCodeButton}
                  setPromoCode={setPromoCode}
                  captchaToken={captchaToken}
                  selectedPaymentMethod={selectedPaymentMethod}
                  selectedShippingMethod={selectedShippingMethod}
                  shippingMethod={selectedShippingMethod}
                  personalInformation={personalInformation}
                  onEditClick={setActiveStep}
                  paymentMethod={selectedPaymentMethod}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {mediumDevice && (
          <Grid item md={4} xs={12}>
            <Box padding={3} mt={10}>
              <h2
                className="email-h2"
                style={{
                  fontSize: 18,
                  marginBottom: 10,
                }}
              >{t`Your order summary`}</h2>

              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ cursor: "pointer" }}
                onClick={() => setOpenCart(true)}
              >
                <p className="p-text" style={{ fontSize: 14 }}>
                  {t`View Products`} ({cart?.carts?.length})
                </p>
                <ChevronRight fontSize="large" />
              </Box>

              <hr style={{ margin: "30px 0", color: "rgba(0,0,0,.4)" }} />
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <p className="p-text">{t`Subtotal`}</p>
                <p className="p-text-bold">
                  {cart?.sub_total}{" "}
                  {localStorage?.getItem("currency") ??
                    companyInformation?.default_currency}
                </p>
              </Box>

              {cart.shipping_fees !== 0 && (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <p className="p-text">{t`Shipping fee`}</p>
                  <p className="p-text-bold">
                    {cart.shipping_fees || 0}{" "}
                    {localStorage?.getItem("currency") ??
                      companyInformation?.default_currency}
                  </p>
                </Box>
              )}

              {cart.tax !== 0 && (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <p className="p-text">{t`Tax`}</p>
                  <p className="p-text-bold">
                    {cart.tax || 0}{" "}
                    {localStorage?.getItem("currency") ??
                      companyInformation?.default_currency}
                  </p>
                </Box>
              )}

              {cart.discount !== 0 && cart.discount && (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <p className="p-text">{t`Discount`}</p>
                  <p className="p-text-bold" style={{ color: "red" }}>
                    -{cart.discount}{" "}
                    {localStorage?.getItem("currency") ??
                      companyInformation?.default_currency}
                  </p>
                </Box>
              )}

              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <p className="p-text">{t`Total`}</p>
                <p className="p-text-bold">
                  {cart["price-after-discount"]}
                  {/* {cart?.sub_total +
                    (cart?.shipping_fees || 0) -
                    (cart?.discount || 0)}{" "} */}
                  {localStorage?.getItem("currency") ??
                    companyInformation?.default_currency}
                </p>
              </Box>
              <hr style={{ margin: "30px 0", color: "rgba(0,0,0,.4)" }} />
              {activeStep === 3 && (
                <>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <p className="p-text-bold">
                      {promoClicked
                        ? t`Promo code` + ": " + promoCode
                        : t`Add promo code`}
                    </p>
                    {promoClicked && (
                      <IconButton onClick={handleClosePromo}>
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                  {!promoClicked && (
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      <input
                        type="text"
                        id="fname"
                        onChange={(e) => setPromoCode(e.target.value)}
                        name="promoCode"
                        value={promoCode}
                        placeholder={t`Promo code`}
                        className="fname"
                      />
                      <button
                        className="register-button"
                        style={{ width: 100, margin: 0 }}
                        onClick={onPromoCodeButton}
                        disabled={status === "loading"}
                      >
                        {status === "loading" ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "#fff" }}
                          />
                        ) : (
                          t`Apply`
                        )}
                      </button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CheckoutPage;
