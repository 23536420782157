import { ExpandLess, ExpandMore, Language } from "@mui/icons-material";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useAuth } from "../../hooks/useAuth";
import { useSelector } from "react-redux";

const MoreOptionButton = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const [languageAnchor, setLanguageAnchor] = useState(false);
  const [currencyAnchor, setCurrencyAnchor] = useState(false);
  const open = Boolean(anchorEl);
  const openLanguage = Boolean(languageAnchor);
  const currencyOpen = Boolean(currencyAnchor);
  const { logout } = useAuth();
  const { i18n, t } = useTranslation();
  const { companyInformation } = useSelector((state) => state.company);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onSelectLanguage = (language) => {
    i18n.changeLanguage(language);
    handleClose();
    document.location.reload();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGlobalClick = () => {
    if (localStorage.getItem("global") === "true") {
      localStorage.setItem("global", "false");
    } else {
      localStorage.setItem("global", "true");
    }
    localStorage.removeItem("cart_uuid");
    localStorage.removeItem("currency");
    logout();
  };

  const onSelectCurrency = (currency) => {
    localStorage.setItem("currency", currency);
    setCurrencyAnchor(null);
    document.location.reload();
  };

  console.log(localStorage.getItem("global"));

  return (
    <>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        variant="text"
        style={{ color: "#000" }}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <IconButton>
          <Language style={{ color: "#000", margin: 0, padding: 0 }} />
        </IconButton>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          pl={1}
          pr={1}
          width={300}
          display={"flex"}
          flexDirection={"column"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <p
              className="p-text"
              style={{ margin: 0, padding: 0, fontSize: 12 }}
            >{t`Currency`}</p>

            <div onClick={(event) => setCurrencyAnchor(event.currentTarget)}>
              <IconButton
                className="p-text"
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 12,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                {localStorage.getItem("currency") ??
                  companyInformation?.default_currency}
                {openLanguage ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>
            <Menu
              id="basic-menu"
              anchorEl={currencyAnchor}
              open={currencyOpen}
              onClose={() => {
                setCurrencyAnchor(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() =>
                  onSelectCurrency(companyInformation?.default_currency)
                }
              >
                {companyInformation?.default_currency}
              </MenuItem>
              {companyInformation?.default_currency !==
                companyInformation?.secondary_currency && (
                <MenuItem
                  onClick={() =>
                    onSelectCurrency(companyInformation?.secondary_currency)
                  }
                >
                  {companyInformation?.secondary_currency}
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <p
              className="p-text"
              style={{ margin: 0, padding: 0, fontSize: 12 }}
            >{t`Language`}</p>

            <div onClick={(event) => setLanguageAnchor(event.currentTarget)}>
              <IconButton
                className="p-text"
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 12,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                {i18n.language === "ar" ? "ع" : i18n.language || "en"}{" "}
                {openLanguage ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>
            <Menu
              id="basic-menu"
              anchorEl={languageAnchor}
              open={openLanguage}
              onClose={() => {
                setLanguageAnchor(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => onSelectLanguage("en")}>en</MenuItem>
              <MenuItem onClick={() => onSelectLanguage("ar")}>ع</MenuItem>
            </Menu>
          </Box>
          <Button style={{ color: "#000" }} onClick={handleGlobalClick}>
            {localStorage.getItem("global") === "true"
              ? t`go to your region store`
              : t`Go to global store`}
          </Button>
        </Box>
        {/* <MenuItem onClick={() => onSelectLanguage("en")}>en</MenuItem>
        <MenuItem onClick={() => onSelectLanguage("ar")}>ع</MenuItem> */}
      </Menu>
    </>
  );
};

export default MoreOptionButton;
